import { StyleSheet } from '@react-pdf/renderer';
import { globalStyles } from '../../style';

export const styles = StyleSheet.create({
  titleImageWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  regionInfoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: '8px',
    margin: '40px 0 0',
    padding: '20px',
    rowGap: 20,
  },
  regionInfoItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexBasis: '33%',
  },
  regionInfoTitle: {
    ...globalStyles.headlineSmall,
    margin: '0 0 5px',
    color: '#030818',
  },
  regionInfoText: {
    ...globalStyles.headlineTiny,
    margin: '0 0 2.5px',
    color: '#030818',
    opacity: 0.8,
  },
  imagesWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin: 'auto 0 40px',
  },
  deforestationImageWrapper: {
    display: 'flex',
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderRadius: '8px',
    marginBottom: '5px',
  },
  deforestationImage: {
    width: '240px',
    height: '240px',
    opacity: 0.9,
    borderRadius: '8px',
    objectFit: 'contain',
  },
  mapCanvasWrapper: {
    marginBottom: '5px',
  },
  mapCanvasImage: {
    borderRadius: '8px',
    width: '240px',
    height: '240px',
    objectFit: 'cover',
  },
});
