import { CircularProgress } from '@mui/material';
import { Document, Font, PDFViewer } from '@react-pdf/renderer';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { COMMON_FEATURE_BODY_TYPE } from '../../constants/data-board';
import { usePolygonContext } from '../../context/Polygon';
import {
  CarbonAccountingData,
  ForestCoverData,
  ForestDataType,
  ForestTemporalType,
} from '../../context/Polygon/types';
import { setSources } from '../../redux/features/map/map-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { AreaUnitType } from '../../types/Geo';
import ManropeBold from './Assets/Fonts/Manrope-Bold.ttf';
import ManropeExtraBold from './Assets/Fonts/Manrope-ExtraBold.ttf';
import ManropeMedium from './Assets/Fonts/Manrope-Medium.ttf';
import ManropeRegular from './Assets/Fonts/Manrope-Regular.ttf';
import ManropeSemiBold from './Assets/Fonts/Manrope-SemiBold.ttf';
import CarbonAboveGroundBiomassPage from './Pages/CarbonAboveGroundBiomassPage';
// import CarbonChangeInBiomassPage from './Pages/CarbonChangeInBiomassPage';
import { bbox } from '@turf/bbox';
import CarbonSequesteredPage from './Pages/CarbonSequesteredPage';
import CoverPage from './Pages/Cover Page';
import DeforestationImagesPage from './Pages/Deforestation Images Page';
import DeforestationYoYPage from './Pages/DeforestationYoYPage';
import DisclaimerPage from './Pages/Disclaimer Page';
import ForestCoverPage from './Pages/ForestCoverPage';
import RegionOverviewPage from './Pages/Region Overview Page';
import TablePage from './Pages/TablePage';
import {
  globalStyles,
  LoadingSubTitle,
  LoadingTitle,
  LoadingWrapper,
} from './style';

// Author: Jakub Wojtach
// NOTE: Carbon Change In Biomass Page is currently commented out due to the error in data
// Do not remove the commented code as it will be used in the future
// Requested by: Mackenzie Mikkelsen

const PDFReport = () => {
  const dispatch = useAppDispatch();
  const {
    getPolygonCarbonAccountingData,
    getPolygonForestCoverData,
    calculatedArea,
  } = usePolygonContext();
  const { user } = useAppSelector((state) => state.userState);
  const { selectedPolygon, deforestation } = useAppSelector(
    (state) => state.regionState
  );
  const { mapRoot } = useMap();
  const [isLoading, setIsLoading] = useState(true);
  const [mapCanvasData, setMapCanvasData] = useState<string | null>(null);

  const { t } = useTranslation();
  const areaUnit = user?.settings.unit as AreaUnitType;

  const [isPdfLoaded, setIsPdfLoaded] = useState(false);
  // Carbon Data
  // sequestered-carbon - data
  const [carbonSequesteredData, setCarbonSequesteredData] =
    useState<CarbonAccountingData[]>();
  // sequestered-carbon - data/area
  const [carbonSequesteredDataArea, setCarbonSequesteredDataArea] =
    useState<CarbonAccountingData[]>();
  // above-ground-biomass - data
  const [carbonAboveGroundBiomassData, setCarbonAboveGroundBiomassData] =
    useState<CarbonAccountingData[]>();
  // above-ground-biomass - data/area
  const [
    carbonAboveGroundBiomassDataArea,
    setCarbonAboveGroundBiomassDataArea,
  ] = useState<CarbonAccountingData[]>();
  // change-in-biomass - data
  const [, setCarbonChangeInBiomassData] = useState<CarbonAccountingData[]>();
  // change-in-biomass - data/area
  const [, setCarbonChangeInBiomassDataArea] =
    useState<CarbonAccountingData[]>();

  // Forest Cover Data
  // area
  const [forestCoverArea, setForestCoverArea] = useState<ForestCoverData[]>();
  // percentage
  const [forestCoverPercentage, setForestCoverPercentage] =
    useState<ForestCoverData[]>();
  // year-over-year-area
  const [forestCoverYoYArea, setForestCoverYoYArea] =
    useState<ForestCoverData[]>();
  // year-over-year-percentage
  const [forestCoverYoYPercentage, setForestCoverYoYPercentage] =
    useState<ForestCoverData[]>();

  const fetchData = useCallback(async () => {
    if (!selectedPolygon) return;
    setIsPdfLoaded(false);
    setIsLoading(true);

    const query = COMMON_FEATURE_BODY_TYPE(selectedPolygon);

    const promises = [
      getPolygonCarbonAccountingData(query, 'sequestered-carbon', 'data', true),
      getPolygonCarbonAccountingData(
        query,
        'sequestered-carbon',
        'data/area',
        true
      ),
      getPolygonCarbonAccountingData(
        query,
        'above-ground-biomass',
        'data',
        true
      ),
      getPolygonCarbonAccountingData(
        query,
        'above-ground-biomass',
        'data/area',
        true
      ),
      getPolygonCarbonAccountingData(query, 'change-in-biomass', 'data', true),
      getPolygonCarbonAccountingData(
        query,
        'change-in-biomass',
        'data/area',
        true
      ),
      getPolygonForestCoverData(
        query,
        ForestTemporalType.LINEAR,
        ForestDataType.AREA,
        true
      ),
      getPolygonForestCoverData(
        query,
        ForestTemporalType.LINEAR,
        ForestDataType.PERCENTAGE,
        true
      ),
      getPolygonForestCoverData(
        query,
        ForestTemporalType.YoY,
        ForestDataType.AREA,
        true
      ),
      getPolygonForestCoverData(
        query,
        ForestTemporalType.YoY,
        ForestDataType.PERCENTAGE,
        true
      ),
    ];

    const [
      carbon,
      carbonArea,
      carbonAGB,
      carbonAGBArea,
      biomassChange,
      biomassChangeArea,
      forestArea,
      forestPercent,
      forestYoYArea,
      forestYoYPercent,
    ] = await Promise.all(promises);

    setCarbonSequesteredData(carbon as CarbonAccountingData[]);
    setCarbonSequesteredDataArea(carbonArea as CarbonAccountingData[]);
    setCarbonAboveGroundBiomassData(carbonAGB as CarbonAccountingData[]);
    setCarbonAboveGroundBiomassDataArea(
      carbonAGBArea as CarbonAccountingData[]
    );
    setCarbonChangeInBiomassData(biomassChange as CarbonAccountingData[]);
    setCarbonChangeInBiomassDataArea(
      biomassChangeArea as CarbonAccountingData[]
    );
    setForestCoverArea(forestArea as ForestCoverData[]);
    setForestCoverPercentage(forestPercent as ForestCoverData[]);
    setForestCoverYoYArea(forestYoYArea as ForestCoverData[]);
    setForestCoverYoYPercentage(forestYoYPercent as ForestCoverData[]);

    setIsLoading(false);
  }, [
    getPolygonCarbonAccountingData,
    getPolygonForestCoverData,
    selectedPolygon,
  ]);

  const captureMapScreenshot = useCallback(() => {
    if (selectedPolygon) {
      dispatch(setSources(['hidden']));
      const polygonBBox = bbox(selectedPolygon);

      mapRoot?.fitBounds(
        [polygonBBox[0], polygonBBox[1], polygonBBox[2], polygonBBox[3]],
        {
          duration: 300,
        }
      );

      setTimeout(() => {
        const mapCanvas = mapRoot?.getCanvas().toDataURL('image/png');
        if (mapCanvas) {
          setMapCanvasData(mapCanvas);
        }
      }, 300);
    }
  }, [dispatch, mapRoot, selectedPolygon]);

  useEffect(() => {
    void fetchData();
    captureMapScreenshot();
  }, [captureMapScreenshot, fetchData]);

  useEffect(() => {
    Font.register({
      family: 'Manrope',
      fonts: [
        {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          src: ManropeRegular,
          fontWeight: 400,
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          src: ManropeMedium,
          fontWeight: 500,
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          src: ManropeSemiBold,
          fontWeight: 600,
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          src: ManropeBold,
          fontWeight: 700,
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          src: ManropeExtraBold,
          fontWeight: 800,
        },
      ],
    });
  }, []);

  const onPdfRender = () => {
    setIsPdfLoaded(true);
  };

  // Create Document Component
  return (
    <>
      {isLoading || !isPdfLoaded ? (
        <LoadingWrapper>
          <CircularProgress color="success" />
          <LoadingTitle>{t('Data is being converted into PDF')}</LoadingTitle>
          <LoadingSubTitle>
            {t('Please wait for the process to finish')}
          </LoadingSubTitle>
        </LoadingWrapper>
      ) : null}
      <div
        style={!isPdfLoaded ? { display: 'none' } : {}}
        data-test-id="pdf-report"
      >
        {!isLoading ? (
          <PDFViewer
            height={window.innerHeight - 100}
            style={globalStyles.pdfViewer}
          >
            <Document onRender={() => onPdfRender()}>
              {selectedPolygon?.geometry || selectedPolygon?._geometry ? (
                <>
                  <CoverPage regionName={selectedPolygon.name || ''} />
                  {deforestation && mapCanvasData && calculatedArea > 0 ? (
                    <RegionOverviewPage
                      deforestationData={deforestation}
                      mapCanvasData={mapCanvasData}
                      selectedPolygon={selectedPolygon}
                      calculatedArea={calculatedArea}
                      areaUnit={areaUnit}
                    />
                  ) : null}
                  {carbonSequesteredData &&
                  carbonSequesteredDataArea &&
                  forestCoverArea ? (
                    <TablePage
                      carbonSequesteredData={carbonSequesteredData}
                      carbonSequesteredDataArea={carbonSequesteredDataArea}
                      forestCoverArea={forestCoverArea}
                      areaUnit={areaUnit}
                      regionName={selectedPolygon.name || ''}
                    />
                  ) : null}
                  {deforestation ? (
                    <DeforestationImagesPage
                      deforestationData={deforestation}
                      regionName={selectedPolygon.name || ''}
                    />
                  ) : null}
                  {carbonSequesteredData && carbonSequesteredDataArea ? (
                    <CarbonSequesteredPage
                      carbonSequesteredData={carbonSequesteredData}
                      carbonSequesteredDataArea={carbonSequesteredDataArea}
                      areaUnit={areaUnit}
                      regionName={selectedPolygon.name || ''}
                    />
                  ) : null}
                  {carbonAboveGroundBiomassData &&
                  carbonAboveGroundBiomassDataArea ? (
                    <CarbonAboveGroundBiomassPage
                      carbonAboveGroundBiomassData={
                        carbonAboveGroundBiomassData
                      }
                      carbonAboveGroundBiomassDataArea={
                        carbonAboveGroundBiomassDataArea
                      }
                      areaUnit={areaUnit}
                      regionName={selectedPolygon.name || ''}
                    />
                  ) : null}
                  {/*{carbonChangeInBiomassData &&*/}
                  {/*carbonChangeInBiomassDataArea ? (*/}
                  {/*  <CarbonChangeInBiomassPage*/}
                  {/*    carbonChangeInBiomassData={carbonChangeInBiomassData}*/}
                  {/*    carbonChangeInBiomassDataArea={*/}
                  {/*      carbonChangeInBiomassDataArea*/}
                  {/*    }*/}
                  {/*    areaUnit={areaUnit}*/}
                  {/*    regionName={selectedPolygon.name || ''}*/}
                  {/*  />*/}
                  {/*) : null}*/}
                  {forestCoverArea &&
                  forestCoverPercentage &&
                  calculatedArea ? (
                    <ForestCoverPage
                      forestCoverArea={forestCoverArea}
                      forestCoverPercentage={forestCoverPercentage}
                      areaUnit={areaUnit}
                      calculatedArea={calculatedArea}
                      regionName={selectedPolygon.name || ''}
                    />
                  ) : null}
                  {forestCoverYoYArea && forestCoverYoYPercentage ? (
                    <DeforestationYoYPage
                      areaYoY={forestCoverYoYArea} // forestCoverYoYArea caontains forest cover as well as deforestation YoY data
                      percentageYoY={forestCoverYoYPercentage} // forestCoverYoYPercentage caontains forest cover as well as deforestation YoY data
                      areaUnit={areaUnit}
                      regionName={selectedPolygon.name || ''}
                    />
                  ) : null}
                  <DisclaimerPage regionName={selectedPolygon.name || ''} />
                </>
              ) : null}
            </Document>
          </PDFViewer>
        ) : null}
      </div>
    </>
  );
};

export default PDFReport;
