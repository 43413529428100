import {
  CarbonAccountingData,
  CarbonDisplayPerType,
  ForestDataType,
  ForestTemporalType,
} from '../context/Polygon/types';
import { IBiomass, TONNE_TO_MEGATONS_RATIO } from '../types/Geo';
import { IUser } from '../types/User';
import { formatTitleByQuarters } from './helpers';
import { returnTonneOrKilograms } from './units';

export const formatCarbonData = (
  biomassData: IBiomass,
  carbonAccountingData: CarbonDisplayPerType,
  user?: IUser | null
): CarbonAccountingData[] => {
  const { data, times } = biomassData;
  return data
    .map((el, index) => ({
      name: formatTitleByQuarters(times[index]),
      carbon:
        carbonAccountingData === 'data'
          ? el / TONNE_TO_MEGATONS_RATIO
          : returnTonneOrKilograms(el, user),
      time: times[index].split('T')[0],
    }))
    .filter((el) => el.carbon);
};

export const formatForestData = (
  biomassData: IBiomass,
  temporalType: ForestTemporalType,
  dataType: ForestDataType,
  area: number
) => {
  const { data, times } = biomassData;

  const returnDeforestationAndForestForElement = (el: number) => {
    if (
      temporalType === ForestTemporalType.LINEAR &&
      dataType === ForestDataType.PERCENTAGE
    ) {
      return {
        forest: el ? 100 - el : 100,
        deforestation: el ? el : 0,
      };
    }

    if (
      temporalType === ForestTemporalType.LINEAR &&
      dataType === ForestDataType.AREA
    ) {
      return {
        forest: area - el,
        deforestation: el,
      };
    }

    return {
      deforestation: el,
    };
  };

  return data.map((el, index) => {
    return {
      ...returnDeforestationAndForestForElement(el),
      name: formatTitleByQuarters(times[index]),
      time: times[index].split('T')[0],
    };
  });
};

// Function to check if the entered string is a valid point coordinate
export const isValidPointCoordinate = (str: string) => {
  const parts = str.split(',');
  if (parts.length !== 2) return false;

  const lat = parseFloat(parts[0]);
  const lng = parseFloat(parts[1]);

  return (
    !isNaN(lat) &&
    lat >= -90 &&
    lat <= 90 &&
    !isNaN(lng) &&
    lng >= -180 &&
    lng <= 180
  );
};
