import styled from 'styled-components';
import { IconWrapper } from '../../Common/Icon/style';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 110px;
  left: -93px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(18, 22, 36, 0.7);
  box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blackII};
`;

export const FloatingButton = styled.button`
  height: 90px;
  width: 93px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 13px;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blackII};
  transition: ${({ theme }) => theme.transition};

  &:hover {
    background-color: ${({ theme }) => theme.colors.blackII};
  }

  &:last-of-type {
    border: 0;
  }

  ${IconWrapper} {
    margin-bottom: 8px;
  }
`;
