import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'usehooks-ts';
import Icon from '../../../components/Common/Icon';
import SwitchComponent from '../../../components/Common/Switch';
import { useActionsMenu } from '../../../hooks/useActionsMenu';
import { openModal } from '../../../redux/features/modal/modal-slice';
import { setRegionToEditOrDelete } from '../../../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  Button,
  ContentWrapper,
  Section,
  SubscribeDescription,
  SubscribeDetails,
  SubscribeTitle,
  ToggleWrapper,
} from './style';

const ActionsMenu = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const menuRef = useRef<HTMLDivElement>(null);

  const { selectedPolygon } = useAppSelector((state) => state.regionState);
  const { user } = useAppSelector((state) => state.userState);

  const [visible, setVisible] = useState(false);
  useOnClickOutside(menuRef, () => setVisible(false));

  const { handleSubscribe } = useActionsMenu();

  return (
    <div ref={menuRef}>
      {user?.role !== 'free' && selectedPolygon?.userId ? (
        <ToggleWrapper
          data-test-id="actions-menu-toggle"
          onClick={() => setVisible((wasActive) => !wasActive)}
        >
          <Icon variant="THREE_DOTS_HORIZONTAL" size={32} color="white" />
        </ToggleWrapper>
      ) : null}
      <ContentWrapper active={visible} data-test-id="actions-menu-content">
        {selectedPolygon?.userId && user?.role !== 'free' ? (
          <Section isSubscribe>
            <SubscribeDetails>
              <SubscribeTitle data-test-id="actions-menu-section-title">
                {t('Subscribe to Data Notifications')}
              </SubscribeTitle>
              <SubscribeDescription data-test-id="actions-menu-section-description">
                {t(
                  'Receive emails when new data for this region becomes available. You are currently'
                )}{' '}
                <strong>
                  {selectedPolygon?.isSubscribed
                    ? t('subscribed')
                    : t('not subscribed')}
                </strong>
              </SubscribeDescription>
            </SubscribeDetails>
            <SwitchComponent
              checked={selectedPolygon?.isSubscribed || false}
              onToggle={handleSubscribe}
              dataTestId={'actions-menu-toggle-subscribe'}
            />
          </Section>
        ) : null}
        {selectedPolygon?.userId ? (
          <Section>
            <Button
              data-test-id="actions-menu-delete-region"
              onClick={() => {
                dispatch(setRegionToEditOrDelete(selectedPolygon));
                dispatch(openModal('deleteRegion'));
              }}
              isRed
            >
              {t('Delete Region')}
            </Button>
          </Section>
        ) : null}
      </ContentWrapper>
    </div>
  );
};

export default ActionsMenu;
