import { Image, Text, View } from '@react-pdf/renderer';
import { formatTitleByQuarters } from '../../../../utils/helpers';
import { styles } from './style';

type ImageGridProps = {
  images: string[];
  times: string[];
};

const ImageGrid = ({ images, times }: ImageGridProps) => {
  return (
    <View style={styles.container}>
      {images.map((el, index) => (
        <View key={`image-${index}`} style={styles.imageWrapper}>
          <Image src={el} style={styles.image} />
          <Text style={styles.imageText}>
            {formatTitleByQuarters(times[index])}
          </Text>
        </View>
      ))}
    </View>
  );
};

export default ImageGrid;
